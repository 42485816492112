import React from "react";
import Flex from "app/components/Flex";
import { DropdownMenu, DropdownOption } from "app/components/Dropdown";
import { SortOrder } from "services/graphql";
import { MenuButton } from "./MenuButton";

interface Props {
  setOrderDirection(orderDirection: SortOrder): void;
}

export function SortMenuOptions({ setOrderDirection }: Props) {
  return (
    <Flex>
      <DropdownMenu menuButton={MenuButton}>
        <DropdownOption
          onClick={() => setOrderDirection(SortOrder.Desc)}
          text="Recently Played"
        />
      </DropdownMenu>
    </Flex>
  );
}
